import axios from 'axios'

import newPromocodesList from './newPromocodesList.json' // edited 311022
import promocodesUpdateList from './promocodesUpdateList.json' // edited 161121
import cityList from '../old/cityListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const addPromocodeApi = (userData) => {
    // правки в промокоды вносим через файл json!!!
    const { token } = userData
    // console.log("🚀 ~ file: promocodesApi.js ~ line 4 ~ promocodesList", promocodesList)

    Object.values(newPromocodesList).forEach((item, index) => {

        // const dataNewPromocode = {
        //     manager: item.manager,
        //     promocode: item.promocode.toLowerCase(),
        //     doctor: item.doctor,
        //     clinic: item.clinic,
        //     address: item.address,
        //     city: item.city,
        //     region: item.region,
        //     phone: item.phone.toString(),
        //     doctorCategory: item.doctorCategory,
        //     specialistType: item.specialistType,
        //     loyalty: item.loyalty,
        //     bonusMethod: item.bonusMethod,
        //     cardNumber: item.cardNumber
        // }
        // console.log("🚀 ~ file: promocodesApi.js ~ line 29 ~ Object.values ~ dataNewPromocode", dataNewPromocode)
        const dataNewPromocode = {
            manager: 52,
            promocode: item.promocode,
            doctor: '',
            clinic: '',
            city: '',
            region: '',
            phone: '',
            cardNumber: '',
            locale: "uk-UA",
            doctorBonusPercent: 15
        }
        console.log("🚀 ~ file: promocodesApi.js ~ line 42 ~ Object.values ~ dataNewPromocode", dataNewPromocode)

        axios
            .post(`${BACKEND_DOMAIN}/promocodes`, dataNewPromocode,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    console.log(response);
                });
    })
}
export const manualUpdatePromocodeApi = (userData, promocodes) => {
    const { token } = userData
    const failedUpdates = [];

    Object.values(promocodesUpdateList).forEach((item, index) => {
        const promocodeIndex = promocodes.findIndex(element => element.promocode.toLowerCase() == item.promocode.toLowerCase());
        const promocodeData = promocodeIndex !== -1 ? promocodes[promocodeIndex] : ''

        const promocodeId = promocodeData.id

        if (promocodeIndex > 0 && promocodeId && item.doctor !== '') {
            const dataPromocode = {
                manager: item.managerId,
                promocode: item.promocode.toLowerCase(),
                doctor: item.doctor,
                clinic: item.clinic,
                address: item.address,
                city: item.city,
                region: item.region,
                phone: item.phone.toString(),
                doctorCategory: item.doctorCategory,
                specialistType: item.specialistType,
                loyalty: item.loyalty,
                bonusMethod: item.bonusMethod,
                cardNumber: item.cardNumber.toString(),
                activated: item.doctor !== '' ? true : false
            }

            axios
                .put(`${BACKEND_DOMAIN}/promocodes/${promocodeId}`, dataPromocode,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then(response => {
                        console.log(response);
                    }).catch(error => {
                        console.log("🚀 ~ Object.values ~ error:", error)
                        // If the update fails, add the promocode to the failedUpdates array
                        failedUpdates.push(item);
                    });
        } else {
            // If the promocode doesn't meet the update criteria, add it to the failedUpdates array
            failedUpdates.push(item);
        }
    })

    // After all promocodes have been processed, if there are any failed updates, write them to a JSON file
    if (failedUpdates.length > 0) {
        console.log('Failed updates:', failedUpdates);
    }
}

export const addCitiesApi = (userData) => {

    const { token } = userData


    cityList.forEach((item, index) => {

        const dataCity = {
            city: Object.keys(item)[0],
            region: Object.values(item)[0],
            locale: "uk-UA"
        }

        axios
            .post(`${BACKEND_DOMAIN}/cities`, dataCity,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    console.log(response);
                });
    })
}

export const getManagerPromocodeListApi = async (userData) => {
    const { token, managerId, managerLocale } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes?_limit=-1&_locale=${managerLocale}&manager=${managerId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagersPromocodeListApi = async (userData, managerId, location) => {
    const { token } = userData
    const allManagers = managerId === 0 ? '' : `&manager=${managerId}`
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes?_limit=-1${allManagers}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updatePromocodeByIdApi = async (id, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/promocodes/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response
        });
    return res;
}

export const getManagerPromocodeSumListApi = async (userData) => { // temporarily used
    const { token, managerId, managerLocale, settingsData } = userData

    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses/calcBonuses`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getManagerPromocodeBonusListApi = async (userData, filterMonth, filterYear) => {
    const { token, managerId, managerLocale, settingsData } = userData

    const month = filterMonth ? filterMonth : settingsData.monthData
    const year = filterYear ? filterYear : settingsData.yearData

    // const res2 = await getManagerPromocodeSumListApi(userData) //temporarily used
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses?_limit=-1&_locale=${managerLocale}&manager=${managerId}&bonusMonth=${month}&bonusYear=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerPromocodeBonusListApi = async (userData, year, month, managerId, location) => {
    const { token, managerLocale } = userData
    const allManagers = managerId === 0 ? '' : `&manager=${managerId}`
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses?_limit=-1&_locale=${managerLocale}${allManagers}&bonusMonth=${month}&bonusYear=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updatePromocodeBonusByIdApi = async (id, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/promocodes-bonuses/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response
        });
    return res;
}

export const manualUpdatePromocodeBonusApi = (userData, bonuses, currYear, currMonth) => {
    const { token } = userData
    const failedUpdates = [];

    Object.values(promocodesUpdateList).forEach((item, index) => {
        const promocodeIndex = bonuses.findIndex(element => element.promocode.toLowerCase() == item.promocode.toLowerCase() && element.bonusMonth === currMonth && element.bonusYear === currYear && element.bonusMonth === item.bonusMonth);
        const promocodeData = promocodeIndex !== -1 ? bonuses[promocodeIndex] : ''

        const promocodeId = promocodeData.id
        const bonusRecommended = promocodeData.bonusRecommended

        if (promocodeIndex > 0 && promocodeId && item.doctor !== '') {
            const dataPromocode = {
                toPaySum: item.toPaySum,
                bonusRecommended: item.bonusRecommended,
                toPay: item.toPay == "TRUE" ? true : false,
                isPayed: item.isPayed == "TRUE" ? true : false
            }
            console.log("🚀 ~ Object.values ~ dataPromocode:", dataPromocode)

            axios
                .put(`${BACKEND_DOMAIN}/promocodes-bonuses/${promocodeId}`, dataPromocode,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then(response => {
                        console.log(response);
                    }).catch(error => {
                        console.log("🚀 ~ Object.values ~ error:", error)
                        // If the update fails, add the promocode to the failedUpdates array
                        failedUpdates.push(item);
                    });
        } else {
            // If the promocode doesn't meet the update criteria, add it to the failedUpdates array
            failedUpdates.push(item);
        }
    })

    // After all promocodes have been processed, if there are any failed updates, write them to a JSON file
    if (failedUpdates.length > 0) {
        console.log('Failed updates:', failedUpdates);
    }
}